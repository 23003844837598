import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import teamone from '../../../assets/images/resource/Website/UK maps/Titanic/Bristol.jpg'
import teamtwo from '../../../assets/images/resource/Website/UK maps/Titanic/Stoke-on-Trent.jpg'
import teamthree from '../../../assets/images/resource/Website/UK maps/Titanic/Manchester.jpg'
import manSmoking from "../../../assets/images/resource/BC-TitanicPoster-Layers_NEW_DESIGN_LESS_INFO.jpg"
import menFashion from "../../../assets/images/twoOldMen.jpg"
import womenFashion from "../../../assets/images/womenFashion2.jpg"
import warning from "../../../assets/images/warning3.png"
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../Fourmenu/Deliciousmenu';
import { useEffect } from 'react';


import BathImg from '../../../assets/images/resource/Website/UK maps/Titanic/Bath.jpg';
import BirminghamImg from '../../../assets/images/resource/Website/UK maps/Titanic/Birmingham.jpg';
import BlackpoolImg from '../../../assets/images/resource/Website/UK maps/Titanic/Blackpool.jpg';
import BournemouthImg from '../../../assets/images/resource/Website/UK maps/Titanic/Bournemouth.jpg';
import BrightonImg from '../../../assets/images/resource/Website/UK maps/Titanic/Brighton.jpg';
import BristolImg from '../../../assets/images/resource/Website/UK maps/Titanic/Bristol.jpg';
import CambridgeImg from '../../../assets/images/resource/Website/UK maps/Titanic/Cambridge.jpg';
import CardiffImg from '../../../assets/images/resource/Website/UK maps/Titanic/Cardiff.jpg';
import CheltenhamImg from '../../../assets/images/resource/Website/UK maps/Titanic/Cheltenham.jpg';
import CoventryImg from '../../../assets/images/resource/Website/UK maps/Titanic/Coventry.jpg';
import DundeeImg from '../../../assets/images/resource/Website/UK maps/Titanic/Dundee.jpg';
import EdinburghImg from '../../../assets/images/resource/Website/UK maps/Titanic/Edinburgh.jpg';
import ExeterImg from '../../../assets/images/resource/Website/UK maps/Titanic/Exeter.jpg';
import HullImg from '../../../assets/images/resource/Website/UK maps/Titanic/Hull.jpg';
import IpswichImg from '../../../assets/images/resource/Website/UK maps/Titanic/Ipswich.jpg';
import LeedsImg from '../../../assets/images/resource/Website/UK maps/Titanic/Leeds.jpg';
import LeicesterImg from '../../../assets/images/resource/Website/UK maps/Titanic/Leicester.jpg';
import LiverpoolImg from '../../../assets/images/resource/Website/UK maps/Titanic/Liverpool.jpg';
import LondonImg from '../../../assets/images/resource/Website/UK maps/Titanic/London.jpg';
import ManchesterImg from '../../../assets/images/resource/Website/UK maps/Titanic/Manchester.jpg';
import MiddlesbroughImg from '../../../assets/images/resource/Website/UK maps/Titanic/Middlesbrough.jpg';
import MiltonKeynesImg from '../../../assets/images/resource/Website/UK maps/Titanic/Milton Keynes.jpg';
import NewcastleImg from '../../../assets/images/resource/Website/UK maps/Titanic/Newcastle upon Tyne.jpg';
import NorthamptonImg from '../../../assets/images/resource/Website/UK maps/Titanic/Northampton.jpg';
import NorwichImg from '../../../assets/images/resource/Website/UK maps/Titanic/Norwich.jpg';
import OxfordImg from '../../../assets/images/resource/Website/UK maps/Titanic/Oxford.jpg';
import PeterboroughImg from '../../../assets/images/resource/Website/UK maps/Titanic/Peterborough.jpg';
import PlymouthImg from '../../../assets/images/resource/Website/UK maps/Titanic/Plymouth.jpg';
import ReadingImg from '../../../assets/images/resource/Website/UK maps/Titanic/Reading.jpg';
import SheffieldImg from '../../../assets/images/resource/Website/UK maps/Titanic/Sheffield.jpg';
import ShrewsburyImg from '../../../assets/images/resource/Website/UK maps/Titanic/Shrewsbury.jpg';
import SouthamptonImg from '../../../assets/images/resource/Website/UK maps/Titanic/Southampton.jpg';
import StokeOnTrentImg from '../../../assets/images/resource/Website/UK maps/Titanic/Stoke-on-Trent.jpg';
import SunderlandImg from '../../../assets/images/resource/Website/UK maps/Titanic/Sunderland.jpg';
import SwanseaImg from '../../../assets/images/resource/Website/UK maps/Titanic/Swansea.jpg'; // Replace with the actual path
import SwindonImg from '../../../assets/images/resource/Website/UK maps/Titanic/Swindon.jpg';
import TunbridgeWellsImg from '../../../assets/images/resource/Website/UK maps/Titanic/Tunbridge Wells.jpg';
import WestWalesImg from '../../../assets/images/resource/Website/UK maps/Titanic/West Wales.jpg';
import WestonSuperMareImg from '../../../assets/images/resource/Website/UK maps/Titanic/Weston-Super-Mare.jpg';
import WrexhamImg from '../../../assets/images/resource/Website/UK maps/Titanic/Wrexham.jpg';
import YorkImg from '../../../assets/images/resource/Website/UK maps/Titanic/York.jpg';


//new one s
import LincolnImg from '../../../assets/images/resource/Website/UK maps/Titanic/Lincoln.png';
import NottinghamImg from '../../../assets/images/resource/Website/UK maps/Titanic/Nottingham.jpg';
import IsleOfManImg from '../../../assets/images/resource/Website/UK maps/Titanic/Isle of Man.jpg';
import BurnleyImg from '../../../assets/images/resource/Website/UK maps/Titanic/Burnley.jpg';
import StaffordImg from '../../../assets/images/resource/Website/UK maps/Titanic/Stafford.jpg';
import CornwallImg from '../../../assets/images/resource/Website/UK maps/Titanic/Cornwall.jpg';
import DevonImg from '../../../assets/images/resource/Website/UK maps/Titanic/Devon.jpg';
import WorcesterImg from '../../../assets/images/resource/Website/UK maps/Titanic/Worcester.jpg';
import DerbyImg from '../../../assets/images/resource/Website/UK maps/Titanic/Derby.jpg';
import CarlisleImg from '../../../assets/images/resource/Website/UK maps/Titanic/Carlisle.jpg';
import AberdeenImg from '../../../assets/images/resource/Website/UK maps/Titanic/Aberdeen.jpg';
import GlasgowImg from '../../../assets/images/resource/Website/UK maps/Titanic/Glasgow.jpg';
import MidWalesImg from '../../../assets/images/resource/Website/UK maps/Titanic/Mid Wales.jpg';
import PortsmouthImg from '../../../assets/images/resource/Website/UK maps/Titanic/Portsmouth.jpg';


function Startermenu() {
  const [tabMenu, tabActive] = useState({ a: true });
  const [showData, setShowData] = useState([]);
  const value = "Titanic";
  const [imageSource, setImageSource] = useState("");

  const boxStyles = {
    padding: "0px",
  };

  const findImgPath = (selectWhere) => {
    switch (selectWhere) {
      case "Bath":
        return BathImg;
      case "Birmingham":
        return BirminghamImg;
      case "Blackpool":
        return BlackpoolImg;
      case "Bournemouth":
        return BournemouthImg;
      case "Brighton":
        return BrightonImg;
      case "Bristol":
        return BristolImg;
      case "Cambridge":
        return CambridgeImg;
      case "Cardiff":
        return CardiffImg;
      case "Cheltenham":
        return CheltenhamImg;
      case "Coventry":
        return CoventryImg;
      case "Dundee":
        return DundeeImg;
      case "Edinburgh":
        return EdinburghImg;
      case "Exeter":
        return ExeterImg;
      case "Hull":
        return HullImg;
      case "Ipswich":
        return IpswichImg;
      case "Leeds":
        return LeedsImg;
      case "Leicester":
        return LeicesterImg;
      case "Liverpool":
        return LiverpoolImg;
      case "London":
        return LondonImg;
      case "Manchester":
        return ManchesterImg;
      case "Middlesbrough":
        return MiddlesbroughImg;
      case "Milton Keynes":
        return MiltonKeynesImg;
      case "Newcastle":
        return NewcastleImg;
      case "Northampton":
        return NorthamptonImg;
      case "Norwich":
        return NorwichImg;
      case "Oxford":
        return OxfordImg;
      case "Peterborough":
        return PeterboroughImg;
      case "Plymouth":
        return PlymouthImg;
      case "Reading":
        return ReadingImg;
      case "Sheffield":
        return SheffieldImg;
      case "Shrewsbury":
        return ShrewsburyImg;
      case "Southampton":
        return SouthamptonImg;
      case "Stoke-on-Trent":
        return StokeOnTrentImg;
      case "Sunderland":
        return SunderlandImg;
      case "Swansea":
        return SwanseaImg;
      case "Swindon":
        return SwindonImg;
      case "TunbridgeWells":
        return TunbridgeWellsImg;
      case "WestWales":
        return WestWalesImg;
      case "Weston-super-Mare":
        return WestonSuperMareImg;
      case "Wrexham":
        return WrexhamImg;
      case "York":
        case "Aberdeen":
          return AberdeenImg;
        case "Burnley":
          return BurnleyImg;
        case "Carlisle":
          return CarlisleImg;
        case "Cornwall":
          return CornwallImg;
        case "Derby": 
          return DerbyImg; 
        case "Devon":
          return DevonImg;
        case "Glasgow":
          return GlasgowImg;
        case "IsleOfMan":
          return IsleOfManImg;
        case "Lincoln":
          return LincolnImg;
        case "MidWales":
          return MidWalesImg;
        case "Nottingham":
          return NottinghamImg;
        case "Portsmouth":
          return PortsmouthImg;
        case "Stafford":
          return StaffordImg;
        case "Worcester":
          return WorcesterImg;
        return YorkImg;
      default:
        return teamthree;
    }
  };

  const fetchData = async (value) => {
    const q = query(
      collection(db, "venues"),
      where("showName", "==", value),
      orderBy("showDate", "asc"),
    );

    try {
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(data);
      return data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData(value).then((data) => {
      setShowData(data);

      console.log(data[0].selectWhere);
      console.log(data[1].selectWhere);
    });
  }, [value]);

  return (
    <>
      <section className="team-section">
        <div className="auto-container">
          <section className="menu-section">
            <div className="auto-container">
              <div className="title-box centered">
                <div className="subtitle">
                  <span>Immersive theatre and dining experience</span>
                </div>

                <div className="pattern-image">
                  <img
                    src={
                      require("../../../assets/images/icons/separator.svg")
                        .default
                    }
                    alt="mySvgImage"
                  />
                </div>

                <h2>Information</h2>
              </div>

              <div className="tabs-box menu-tabs">
                <div className="buttons">
                  <ul className="tab-buttons clearfix">
                    <li
                      className={`tab-btn ${tabMenu.a && "active-btn"}`}
                      onClick={() => tabActive({ a: true })}
                      data-tab="#tab-1"
                    >
                      <span>THE EVENT</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.b && "active-btn"}`}
                      onClick={() => tabActive({ b: true })}
                      data-tab="#tab-2"
                    >
                      <span>DRESS CODE</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.c && "active-btn"}`}
                      onClick={() => tabActive({ c: true })}
                      data-tab="#tab-3"
                    >
                      <span>WARNINGS</span>
                    </li>
                    <li
                      className={`tab-btn ${tabMenu.d && "active-btn"}`}
                      onClick={() => tabActive({ d: true })}
                      data-tab="#tab-4"
                    >
                      <span>MENU</span>
                    </li>
                  </ul>
                </div>
                <div className="tabs-content">
                  <div
                    className={`tab ${tabMenu.a && "active-tab"}`}
                    id="tab-1"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div>
                            Captain Smith and his crew are looking forward to
                            welcoming you and your guests on board the RMS
                            Titanic on her maiden voyage. Our Titanic Themed
                            Dining Experience gives guests a chance to dress up
                            and become a small part of history. The evening
                            begins at 7 pm when Mabel Bennett, the first class
                            stewardess, will call guests to their tables,
                            imagining that it is the 10th of April 1912 and that
                            the first class passengers are about to board the
                            unsinkable Titanic. Throughout the evening as a 3
                            course meal that has been replicated from an actual
                            menu found on a first class survivor is served,
                            you’ll watch how the Captain and some of the female
                            workers on board are affected by the tragic event.
                            Much of the evening is interactive however the final
                            30 minutes are thought-provoking, respectful, and
                            emotional. The night is accompanied by a projection
                            of the Titanic's voyage and an original soundtrack.
                          </div>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner mr-5">
                          <img
                            src={manSmoking}
                            className="rounded mx-5"
                            style={{ height: "300px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.b && "active-tab"}`}
                    id="tab-2"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <h4>1910s style Glamour</h4>

                          <p className="my-3">
                            We recommend all participants embrace the elegance
                            of 1910s fashion.
                          </p>

                          <p className="my-3">
                            Era specific clothing is very appropriate. First
                            Class dining in 1912. Formal dress (shirt and
                            trousers are suitable).
                          </p>

                          <p className="my-3">
                          <p>Please note that the dress code is a recommendation, not a requirement.*</p>
                          </p>
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <img
                            src={menFashion}
                            style={{ height: "300px" }}
                            className="rounded mr-3"
                          />
                          <img
                            src={womenFashion}
                            style={{ height: "300px", width: "250px" }}
                            className="rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.c && "active-tab"}`}
                    id="tab-3"
                  >
                    <div className="row clearfix">
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <div className="mt-4">
                            **Please note this is a sensitive subject and may be
                            upsetting for some.**
                          </div>
                          <div className="my-3">
                            **There is no age restriction to this event however
                            please note that the price and the menu option will
                            remain the same and the content of the show may be
                            upsetting.**
                          </div>
                          
                        </div>
                      </div>
                      <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                        <div className="inner">
                          <img
                            src={warning}
                            style={{ height: "250px", width: "250px" }}
                            className="ml-5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab ${tabMenu.d && "active-tab"}`}
                    id="tab-4"
                  >
                    <div className="row clearfix">
                      <div className="container text-center mb-5">
                        <h4>
                          This is a sample menu and can vary depending on the
                          venue, please contact them directly.
                        </h4>
                      </div>
                      <div className="container text-center">
                        <h2>Starters</h2>
                      </div>
                      <div className="menu-col cols-lg-6 col-md-12 col-sm-12">
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6" id="boxHoverMenu">
                              <h4 class="card-title my-2  d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="inner-box" style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">
                                        Pearl Barley soup{" "}
                                        <span className="s-info">VEGAN</span>
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    {" "}
                                    A root vegetable soup with carrots, swede,
                                    celery, mushrooms, onions and garlic.
                                    Seasoned with thyme and served with a crusty
                                    bread roll.
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 " id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">
                                        Poached Salmon with Mousseline sauce
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    with a light lemon cream sauce and cucumbers
                                    (served cold)
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Mains
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6" id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix">
                                    <h5 className="ml-3">
                                    Vegetable Farci {" "}
                                      <span className="s-info">VEGAN</span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                  Salad leaves dressed with champagne saffron
                                  vinaigrette (served cold)(Vegetarian / Vegan
                                  and gluten free)
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">
                                        Sauté of Chicken Lyonnaise{" "}
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Chicken breast with a rich thyme, onion,
                                    garlic and tomato sauce.{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Dessert
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6" id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegan
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix text-center">
                                    <h5 className="ml-3">
                                      Waldorf pudding{" "}
                                      <span className="s-info text-center">
                                        VEGETARIAN
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                  Baked apples, cinnamon and sweet sponge layers
                                  topped with vanilla ice cream. (Vegetarian /
                                  Vegan – Vegan custard and Vegan ice cream)
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">
                                        Peaches in Chartreuse style Jelly
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    Peaches served with a Grand Marnier Jelly
                                    (contains Gelatin)
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="text-center mb-4">
            <span>
              Please note all our bookings are handled externally by our venue
              host
            </span>
          </div>

          <div className="row justify-content-center clearfix">
            {showData.map((item) => (
              <div className="team-block col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="0ms"
                >
                  <div className="image">
                    <img src={findImgPath(item.selectWhere)} alt="" />
                    <div className="overlay-box">CLICK BELOW TO BOOK</div>
                  </div>
                  <h3>
                    <Link to={item.hotelLink}>{item.hotelName}</Link>
                  </h3>
                  <div className="designation">{item.showDate}</div>
                  <div className="text">{item.hotelLocation}</div>
                  <Link to={item.hotelLink}>
                    <button
                      type="submit"
                      className="theme-btn btn-style-one clearfix mt-2"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Book</span>
                        <span className="text-two">Book</span>
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            ))}

            <div className="team-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="image">
                  <img src={teamthree} alt="" />
                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <ul className="social-box"></ul>
                    </div>
                  </div>
                </div>
                <h3>
                  <Link to="#">Coming soon...</Link>
                </h3>
                <div className="designation">2024</div>
                <div className="text">Coming soon...</div>
                <a href="https://www.quaytickets.com/bowdonrooms/en-GB/shows/the%20queen%20of%20the%20ocean%20-%20titanic%20experience/events">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix mt-3"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Book</span>
                      <span className="text-two">Book</span>
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Startermenu