import Bredcrum from "../Bredcrumb/Main"
import React from "react";
import img from "../../assets/images/resource/tableDark.jpg"
import { useState } from "react";
import { serverTimestamp, addDoc, collection } from "firebase/firestore";
import logo from "../../assets/images/logo.png"
import { db } from "../Allmenus/Fourmenu/Deliciousmenu";



function EventContact() {
  const [formData, setFormData] = useState({
    show: "Titanic",
    venueName: "",
    venueLink: "",
    venueEmail: "",
    venueNumber: "",
    venueMessage: "",
    createdAt: serverTimestamp(),
  });
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const docRef = await addDoc(collection(db, "requests"), {
        show: formData.show,
        venueName: formData.venueName,
        venueLink: formData.venueLink,
        venueEmail: formData.venueEmail,
        venueNumber: formData.venueNumber,
        venueMessage: formData.venueMessage,
        createdAt: formData.createdAt,
      });

      console.log("Document written with ID: ", docRef.id);
      window.alert("Successfully submitted");

      // Clear the form fields or reset the formData state
      setFormData({ name: "", email: "", message: "" });
      setInputValue("");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div>
      <Bredcrum title="Host Histoire" subtitle="Get involved!" Img={img} />
      <section className="contact-page">
        <div className="auto-container">
          <div className="c-page-form-box">
            <div className="row clearfix">
              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <div className="title-box centered">
                  <h2>Interested in hosting one of our events?</h2>
                  <div className="text desc">
                    Please use the form below to leave an enquiry
                  </div>
                </div>
                <div className="default-form reservation-form">
                  <form
                    method="post"
                    target="_self"
                    encType="multipart/form-data"
                    acceptCharset="UTF-8"
                  >
                    <div className="clearfix">
                      <div className="form-group">
                        <div className="field-inner">
                          Please select a show
                          <div className="field-inner">
                            <select
                              className="l-icon"
                              name="show"
                              value={formData.show}
                              onChange={handleChange}
                              id="selectShowF"
                            >
                              <option value="Titanic">Titanic</option>
                              <option value="JFK">JFK</option>
                              <option value="TheOverlookHotel">
                                The Overlook Hotel
                              </option>
                              <option value="NoShowInParticular">
                                No show in particular
                              </option>
                            </select>
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12"></div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="text"
                            name="venueName"
                            placeholder="Venue name"
                            required
                            value={formData.venueName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="text"
                            name="venueLink"
                            placeholder="Venue Link (not required)"
                            required
                            value={formData.venueLink}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="text"
                            name="venueEmail"
                            placeholder="Contact email"
                            required
                            value={formData.venueEmail}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="text"
                            name="venueNumber"
                            placeholder="Contact number"
                            required
                            value={formData.venueNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <textarea
                            name="venueMessage"
                            placeholder="Leave a message"
                            value={formData.venueMessage}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          type="hidden"
                          name="g-recaptcha-response"
                          id="g-recaptcha-response"
                          value=""
                          autocomplete="off"
                        />
                        <button
                          type="submit"
                          className="theme-btn btn-style-one clearfix"
                          onClick={handleSubmit}
                        >
                          <span className="btn-wrap">
                            <span className="text-one">Submit</span>
                            <span className="text-two">Submit</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <img src={logo} style={{ backgroundSize: "cover" }}></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EventContact