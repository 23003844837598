import React from 'react'
import Startermenu from '../Allmenus/FirstMenu/Startermenu';
import Bredcom from '../Bredcrumb/Main'
import Img from '../../assets/images/resource/darkBoat.jpg'

function Menuone() {
  return (
    <>
      <Bredcom subtitle="THE QUEEN OF THE OCEAN" title="HISTOIRE PRODUCTIONS" Img={Img} />
      <Startermenu />
      
      
    </>
  )
}

export default Menuone