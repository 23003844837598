import React from 'react';
import { Link } from 'react-router-dom'
import Backgroundimage from '../../assets/images/resource/actualBanner.jpg';
import img1 from "../../assets/images/resource/jfk_poster2.jpg";
import img2 from "../../assets/images/resource/TitanicPoster.jpg";
import img3 from "../../assets/images/resource/OverLookPoster.jpg";
import SwiperCore, { Navigation } from 'swiper/core';
import img4 from '../../assets/images/resource/Scrooge_poster.jpg'
SwiperCore.use([Navigation]);

function Testimonialsabout() {
  const show = {
    slidesPerView: 2,
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };

  return (

    

    <section className="testimonials-two">
  <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})`, opacity: "0.2" }}></div>
  <div className="auto-container">
    <div className="title-box centered">
      <div className="subtitle">
        <span>BUY TICKETS</span>
      </div>
      <div className="pattern-image">
        <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
      </div>
      <h2>Choose a show</h2>
    </div>

    {/* Use Bootstrap's responsive grid classes */}
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="news-block">
          <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
            <div className="image">
              <div className="date"><span>SHOWS AVAILABLE</span></div>
              <div className="image">
                <Link to="/titanic">
                  <img src={img2} alt="" />
                </Link>
              </div>
              <div className='container bg-black rounded mt-2 text-center'>
              
                <h4>
                  <Link to="/Menuone">
                   </Link>
                </h4>
                <Link to="/Menuone">
                  <button type="submit" className="theme-btn btn-style-one clearfix my-2 rounded mb-1">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="news-block">
          <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
            <div className="image">
              <div className="date"><span>SHOWS AVAILABLE</span></div>
              <div className="image">
                <Link to="/overlook">
                  <img src={img3} alt="" />
                </Link>
              </div>
              
              <div className='container bg-black rounded mt-2 text-center'>
              
                <h4>
                  <Link to="/menuthree">
                    
                  </Link>
                </h4>
                <Link to="/menuthree">
                  <button type="submit" className="theme-btn btn-style-one clearfix my-2 rounded mb-1">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="news-block">
          <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
            <div className="image">
              <div className="date"><span>SHOWS AVAILABLE</span></div>
              <div className="image">
                <Link to="/scrooge">
                  <img src={img4} alt="" />
                </Link>
              </div>
              
              <div className='container bg-black rounded mt-2 text-center'>
              
                <h4>
                  <Link to="/scrooge">
                    
                  </Link>
                </h4>
                <Link to="/scrooge">
                  <button type="submit" className="theme-btn btn-style-one clearfix my-2 rounded mb-1">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4  offset-lg-4 col-md-6 col-sm-12">
        <div className="news-block">
          <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
            <div className="image">
              <div className="date"><span>SHOWS AVAILABLE</span></div>
              <div className="image">
                <Link to="/jfk">
                  <img src={img1} alt="" />
                </Link>
              </div>
              <div className='container bg-black rounded mt-2 text-center'>
             
                <h4>
                  <Link to="/Menutwo">
                  </Link>
                </h4>
                <Link to="/Menutwo">
                  <button type="submit" className="theme-btn btn-style-one clearfix my-2 rounded mb-1">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  );
}

export default Testimonialsabout;
