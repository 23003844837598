import React from 'react'
import mainchef from '../../assets/images/resource/Bobby.png'


function Chefsection() {
    return (
        <>
            <section className="chef-section">
                
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-5">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image mr-3"><img src={mainchef} alt="" /></div>
                            </div>
                        </div>

                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="content-box">
                                    <div className="title-box">
                                        <div className="subtitle"><span>Managing director of Histoire Productions</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>
                                        <h2>Bobby Cole</h2>
                                        <div className="text">Bobby Cole is an accomplished song writer, producer and distributor of music. Histoire Productions' unique style of writing and presentation was built upon
                                        the creative experience of Bobby and his team. </div>
                                    </div>
                                    <div className="info">
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Chefsection