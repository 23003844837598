import React from 'react'
import Special from '../Menhome/Special'
import Contact from '../Menhome/Contact'
import Banner from '../Menhome/Banner'
import Offer from '../Menhome/Offer'
import { Link } from 'react-router-dom'



function Main() {
    return (
        <>
            <div className="menu-backdrop"> </div>

            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer"><span className="far fa-close"></span></div>
                    

                    <div className="side-menu">
                        <ul className="navigation clearfix">s
                            <li className="current dropdown"><Link to="#">Home</Link>
                                <ul>
                                    <li><Link to="/">Home 1 Left Header</Link></li>
                                    <li><Link to="/hometwo">Home 1 Center Header</Link></li>
                                    <li><Link to="/">Home 3 Video Hero</Link></li>
                                </ul>
                            </li>
                            <li className="dropdown"><Link to="/menu">Menus</Link>
                                <ul>
                                    <li><Link to="/menuone">Menu List 1</Link></li>
                                    <li><Link to="/menutwo">Menu List 2</Link></li>
                                    <li><Link to="/menuthree">Menu List 3</Link></li>
                                    
                                </ul>
                            </li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/ourchefs">Our Team</Link></li>
                            <li className="dropdown"><Link to="#">Pages</Link>
                                <ul>
                                    <li><Link to="#">Dropdown Menu 1</Link></li>
                                    <li><Link to="#">Dropdown Menu 2</Link></li>
                                    <li><Link to="#">Dropdown Menu 3</Link></li>
                                    <li className="dropdown"><Link to="#">Dropdown Menu 4</Link>
                                        <ul>
                                            <li><Link to="#">Dropdown Menu level 2</Link></li>
                                            <li><Link to="#">Dropdown Menu level 2</Link></li>
                                            <li><Link to="#">Dropdown Menu Level 3</Link></li>
                                        </ul>
                                    </li>
                                    <li><Link to="#">Dropdown Lorem 5</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>

                    <h2>Visit Us</h2>
                    <ul className="info">
                        
                        <li>Open: 8am - 4pm</li>
                        <li><Link to="mailto:info@histoireproductions.com">info@histoireproductions.com</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Booking request</div>
                        <div className="bk-no"><Link to="tel:+88-123-123456">+88-123-12</Link></div>
                    </div>
                </div>
            </section>

            <Banner />
            <Offer />
            
            
            <Special />
            
            
            
            <Contact />
            
        </>
    )
}

export default Main


