import React from 'react'
import Chefsection from '../Menchefs/Chefsection'
import Teem from '../Menhome/Teem'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/resource/teamImage.jpg'

function Main() {
  return (

    <>
      <Bredcrumb subtitle="Meet Our Team" title="OUR TEAM" Img={Img} />
      <Chefsection />
      
      <Teem />
    </>

  )
}

export default Main