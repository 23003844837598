import React from 'react'
import { Link } from 'react-router-dom'
import Iframe from 'react-iframe'
import restro from '../../assets/images/logo.png'

function Contactbanner() {
    return (
        <>
            <div className="contact-map">
  <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.4533868226626!2d-3.9406577842886317!3d51.6193698272459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486e476def0a77c9%3A0x8c8e63e4b40d7cc2!2sSwansea%2C%20UK!5e0!3m2!1sen!2sus!4v1667928043843!5m2!1sen!2sin" width="100%" height="500" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
</div>


            <section className="contact-page">
                

                <div className="location-center">
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">

                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Weekdays</h4>
                                        <div className="text"> Monday to Friday <br /> <div className="more-link"><Link to="#">8am to 4pm</Link></div></div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Contact Info</h4>
                                        <div className="text">Histoire Productions, Swansea UK<br />Email : info@histoireproductions.com</div>
                                        <div className="more-link"><Link to="#">Number: 01792 203135</Link></div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <h4>Weekends</h4>
                                        <div className="text">Saturday and Sunday<br /><div className="more-link"><Link to="#">Closed</Link></div></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="c-page-form-box">
                        <div className="row clearfix">

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <div className="title-box centered">
                                    <h2>Message us</h2>
                                    <div className="text desc">Have a question about the our service? We're here to help, contact us today!</div>
                                </div>
                                <div className="default-form reservation-form">
                                <form
        method="post"
        action="https://formspree.io/f/mrgwlyjb" // Specify your FormKeep endpoint here
        target="_self"
        encType="multipart/form-data"
        acceptCharset="UTF-8"
      >
        <div className="clearfix">
          <div className="form-group">
            <div className="field-inner">
              <input type="text" name="name" placeholder="Your Name" required />
            </div>
          </div>
          <div className="form-group">
            <div className="field-inner">
              <input type="text" name="email" placeholder="Your Email" required />
            </div>
          </div>
          <div className="form-group">
            <div className="field-inner">
              <input type="text" name="phone" placeholder="Phone Number" required />
            </div>
          </div>
          <div className="form-group">
            <div className="field-inner">
              <textarea name="message" placeholder="Special Request" required></textarea>
            </div>
          </div>
          <div className="form-group">
          <input type="hidden" name="g-recaptcha-response" id="g-recaptcha-response" value="" autocomplete="off" />
            <button type="submit" className="theme-btn btn-style-one clearfix">
              <span className="btn-wrap">
                <span className="text-one">send your message</span>
                <span className="text-two">send your message</span>
              </span>
            </button>
          </div>
        </div>
      </form>
                                </div>
                            </div>


                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <img src={restro} alt=""  style={{backgroundSize: "cover"}}/>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Contactbanner