// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxBGjxUYyQYf5A7xejiT6Majc0BIWrZDs",
  authDomain: "histoirebackend.firebaseapp.com",
  projectId: "histoirebackend",
  storageBucket: "histoirebackend.appspot.com",
  messagingSenderId: "1092213300537",
  appId: "1:1092213300537:web:f1ef995f0d898c3c0d5ce8",
  measurementId: "G-CDCWEWL2YF"
};

// Initialize Firebase



export default firebaseConfig