import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/hp2.png'



function Main() {
    const [active, setActive] = useState();
    const [lastscrool, setlastscrool] = useState(0);
    const [isDisplayVisible, setIsDisplayVisible] = useState(true);
    const [scrolled, setScrolled] = useState(false);
    const [Nav, setNav] = useState(true);

    const handleScroll = () => {
        const offset = window.scrollY;
        setlastscrool(offset);

        if (offset > 1000 && offset < lastscrool) {
            setNav(true);
            setScrolled(false);
        }

        else if (offset > 600) {
            setNav(false)
        }

        else if (offset > 25) {
            setNav(true);
            setScrolled(false);
        }

        else {
            setScrolled(false);
            setNav(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        const handleResize = () => {
            setIsDisplayVisible(window.innerWidth >= 1535);
          };

          window.addEventListener('resize', handleResize);

          handleResize();
          return () => {
            window.removeEventListener('resize', handleResize);
          };
    })

    return (
        <>
            {active &&
                <div className="menu-backdrop" style={{ opacity: "1", visibility: "visible" }}></div>
            }
            <section className={`hidden-bar ${active && "visible-sidebar"}`} >
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close" onClick={() => setActive(false)} > </span></div>
                    

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li><Link to="/" onClick={() => setActive(false)}>Home</Link></li>
                            <li><Link to="/ourevents" onClick={() => setActive(false)}>View Our upcoming events</Link></li>
                            
                            
                            <li><Link to="/team" onClick={() => setActive(false)}>Our Team</Link></li>
                           
                            <li><Link to="/contact" onClick={() => setActive(false)}> Contact </Link></li>
                            <li><Link to="/eventContact" onClick={() => setActive(false)}> Host an event </Link></li>
                        </ul>
                    </div>

                     <ul className="info">
                     <div className="logo"><Link to="/" title="Histoire Productions"><img src={logonew} alt="" title="Histoire Productions" /></Link></div>
                        <li>Open: 8am - 4pm Monday to Friday</li>
                        <li><Link to="mailto:info@histoireproductions.com">info@histoireproductions.com</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Fo<Link to="/admin">r</Link> enquiries please call</div>
                        <div className="bk-no"><Link to="tel:+88-123-123456">01792 203 135</Link></div>
                    </div>

                </div>
            </section>
            {Nav &&
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i>Histoire Productions</li>
                                        <li><i className="icon far fa-clock"></i> Weekdays : 8.00 am to 4pm</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        
                                        <li><Link to="tel:+11234567890"><i className="icon far fa-phone"></i>01792 203135</Link> </li>
                                        <li><Link to="mailto:info@histoireproductions.com"><i className="icon far fa-envelope"></i> info@histoireproductions.com</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                
                                
                                <div className="container ml-2">
                                    <div className="logo ml-4 d-none d-sm-block"><Link to="/" title="Histoire Productions"><img src={logonew} alt="" title="Histoire Productions" /></Link></div>
                                </div>

                                <div className="nav-box clearfix">

                                    <div className="nav-outer clearfix">
                                        <nav className="main-menu">
                                            <ul className="navigation clearfix">
                                                <li className="current dropdown"><Link to="/">Home</Link>
                                                    <ul>
                                                        <li> <Link to="/hometwo">Home 1 Left Header</Link></li>
                                                        <li> <Link to="/">Home 1 Center Header</Link></li>
                                                        <li> <Link to="/homethree">Home 3 Video Hero</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown has-mega-menu"><Link to="/menu">Menus</Link>
                                                    <ul>
                                                        <li>
                                                            <div className="mega-menu">
                                                                <div className="menu-inner">
                                                                    <div className="auto-container">
                                                                        <div className="row clearfix">
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/menuone"></Link></div>
                                                                                <div className="title"><Link to="/menuone">Menu list 1</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/menutwo"></Link></div>
                                                                                <div className="title"><Link to="menutwo">Menu list 2</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/menuthree"></Link></div>
                                                                                <div className="title"><Link to="menuthree">Menu list 3</Link></div>
                                                                            </div>
                                                                            <div className="menu-block col-lg-3 col-md-6 col-sm-6">
                                                                                <div className="image"><Link to="/menufour"></Link></div>
                                                                                <div className="title"><Link to="/menufour">Menu list 4</Link></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><Link to="/about">About Us</Link></li>
                                                <li><Link to="/ourchefs">Our chefs</Link></li>
                                                <li className="dropdown"><Link to="/">Pages</Link>
                                                    <ul>
                                                        <li> <Link to="#">Dropdown Menu 1</Link></li>
                                                        <li> <Link to="#">Dropdown Menu 2</Link></li>
                                                        <li> <Link to="#">Dropdown Menu 3</Link></li>
                                                        <li className="dropdown"><Link to="#">Dropdown Menu 4</Link>
                                                            <ul>
                                                                <li> <Link to="#">Dropdown Menu level 2</Link></li>
                                                                <li> <Link to="#">Dropdown Menu level 2</Link></li>
                                                                <li> <Link to="#">Dropdown Menu Level 3</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li><Link to="#">Dropdown Lorem 5</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="#">Blog</Link></li>
                                                <li><Link to="/contact">Contact</Link></li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>

                                {isDisplayVisible && (
                                    <div className="link link-btn">
                                    <Link to="/ourevents" className="theme-btn btn-style-one clearfix d-none d-xl-block">
                                        <span className="btn-wrap">
                                        <span className="text-one">BOOK A TICKET</span>
                                        <span className="text-two">BOOK A TICKET</span>
                                        </span>
                                    </Link>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </header>
            }
        </>
    )
}

export default Main