import React from 'react';
import { Link } from 'react-router-dom';
import teamthree from '../../../assets/images/resource/scrooge_coming_soon.jpg'
import { useState } from 'react';
import manSmoking from "../../../assets/images/resource/Scrooge_Main_Poster.jpg"
import menFashion from "../../../assets/images/twoOldMen.jpg"
import womenFashion from "../../../assets/images/womenFashion2.jpg"
import warning from "../../../assets/images/warning3.png"
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../Fourmenu/Deliciousmenu';
import { useEffect } from 'react';
import veggieMenu from "../../../assets/images/resource/scroogeMenu.png"
import standardMenu from "../../../assets/images/resource/standardScroogeMenun.png"



import BathImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Bath Scrooge.jpg';
import BirminghamImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Birmingham Scrooge.jpg';
import BlackpoolImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Blackpool Scrooge.jpg';
import BournemouthImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Bournemouth Scrooge.jpg';
import BrightonImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Brighton Scrooge.jpg';
import BristolImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Bristol Scrooge.jpg';
import CambridgeImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Cambridge.jpg';
import CardiffImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Cardiff.jpg';
import CheltenhamImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Cheltenham.jpg';
import CoventryImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Coventry.jpg';
import DundeeImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Dundee.jpg';
import EdinburghImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Edinburgh.jpg';
import ExeterImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Exeter.jpg';
import HullImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Hull.jpg';
import IpswichImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Ipswich.jpg';
import LeedsImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Leeds.jpg';
import LeicesterImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Leicester.jpg';
import LiverpoolImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Liverpool.jpg';
import LondonImg from '../../../assets/images/resource/Website/UK maps/Scrooge/London.jpg';
import ManchesterImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Manchester.jpg';
import MiddlesbroughImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Middlesbrough.jpg';
import MiltonKeynesImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Milton Keynes.jpg';
import NewcastleImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Newcastle upon Tyne.jpg';
import NorthamptonImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Northampton.jpg';
import NorwichImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Norwich.jpg';
import OxfordImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Oxford.jpg';
import PeterboroughImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Petersborough.jpg';
import PlymouthImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Plymouth.jpg';
import ReadingImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Reading.jpg';
import SheffieldImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Sheffield.jpg';
import ShrewsburyImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Shrewsbury.jpg';
import SouthamptonImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Southampton.jpg';
import StokeOnTrentImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Stoke-on-Trent.jpg';
import SunderlandImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Sunderland.jpg';
import SwanseaImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Swansea.jpg'; // Replace with the actual path
import SwindonImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Swindon.jpg';
import TunbridgeWellsImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Tunbridge Wells.jpg';
import WestWalesImg from '../../../assets/images/resource/Website/UK maps/Scrooge/West Wales.jpg';
import WestonSuperMareImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Weston-Super-Mare.jpg';
import WrexhamImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Wrexham.jpg';
import YorkImg from '../../../assets/images/resource/Website/UK maps/Scrooge/York.jpg';
//new ones
import LincolnImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Lincoln.jpg';
import NottinghamImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Nottingham.jpg';
import IsleOfManImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Isle of Man.jpg';
import BurnleyImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Burnley.jpg';
import StaffordImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Stafford.jpg';
import CornwallImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Cornwall.jpg';
import DevonImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Devon.jpg';
import WorcesterImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Worcester.jpg';
import DerbyImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Derby.jpg';
import CarlisleImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Carlisle.jpg';
import AberdeenImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Aberdeen.jpg';
import GlasgowImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Glasgow.jpg';
import MidWalesImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Mid Wales.jpg';
import PortsmouthImg from '../../../assets/images/resource/Website/UK maps/Scrooge/Portsmouth.jpg';


function ScroogePage() {
    const [tabMenu, tabActive] = useState({ a: true });
  
    const [showData, setShowData] = useState([]);
    const value = 'Scrooge';
    const [imageSource, setImageSource] = useState("");
    const [loading, setLoading] = useState(true);
    const boxStyles = {
      padding: "0px"
    }
  
  
  
  
  
    const findImgPath = (selectWhere) => {
      switch (selectWhere) {
        case 'Bath':
          return BathImg;
        case 'Birmingham':
          return BirminghamImg;
        case 'Blackpool':
          return BlackpoolImg;
        case 'Bournemouth':
          return BournemouthImg;
        case 'Brighton':
          return BrightonImg;
        case 'Bristol':
          return BristolImg;
        case 'Cambridge':
          return CambridgeImg;
        case 'Cardiff':
          return CardiffImg;
        case 'Cheltenham':
          return CheltenhamImg;
        case 'Coventry':
          return CoventryImg;
        case 'Dundee':
          return DundeeImg;
        case 'Edinburgh':
          return EdinburghImg;
        case 'Exeter':
          return ExeterImg;
        case 'Hull':
          return HullImg;
        case 'Ipswich':
          return IpswichImg;
        case 'Leeds':
          return LeedsImg;
        case 'Leicester':
          return LeicesterImg;
        case 'Liverpool':
          return LiverpoolImg;
        case 'London':
          return LondonImg;
        case 'Manchester':
          return ManchesterImg;
        case 'Middlesbrough':
          return MiddlesbroughImg;
        case 'Milton Keynes':
          return MiltonKeynesImg;
        case 'Newcastle':
          return NewcastleImg;
        case 'Northampton':
          return NorthamptonImg;
        case 'Norwich':
          return NorwichImg;
        case 'Oxford':
          return OxfordImg;
        case 'Peterborough':
          return PeterboroughImg;
        case 'Plymouth':
          return PlymouthImg;
        case 'Reading':
          return ReadingImg;
        case 'Sheffield':
          return SheffieldImg;
        case 'Shrewsbury':
          return ShrewsburyImg;
        case 'Southampton':
          return SouthamptonImg;
        case 'Stoke-on-Trent':
          return StokeOnTrentImg;
        case 'Sunderland':
          return SunderlandImg;
        case 'Swansea':
          return SwanseaImg;
        case 'Swindon':
          return SwindonImg;
        case 'TunbridgeWells':
          return TunbridgeWellsImg;
        case 'WestWales':
          return WestWalesImg;
        case 'Weston-super-Mare':
          return WestonSuperMareImg;
        case 'Wrexham':
          return WrexhamImg;
        case 'York':
          return YorkImg;
        case "Aberdeen":
          return AberdeenImg;
        case "Burnley":
          return BurnleyImg;
        case "Carlisle":
          return CarlisleImg;
        case "Cornwall":
          return CornwallImg;
        case "Derby": 
          return DerbyImg; 
        case "Devon":
          return DevonImg;
        case "Glasgow":
          return GlasgowImg;
        case "IsleOfMan":
          return IsleOfManImg;
        case "Lincoln":
          return LincolnImg;
        case "MidWales":
          return MidWalesImg;
        case "Nottingham":
          return NottinghamImg;
        case "Portsmouth":
          return PortsmouthImg;
        case "Stafford":
          return StaffordImg;
        case "Worcester":
          return WorcesterImg;
        default:
          return teamthree;
      }
    };
  
  
    const fetchData = async (value) => {
      const q = query(collection(db, 'venues'), where('showName', '==', value), orderBy("showDate", "asc"));
  
      try {
        const querySnapshot = await getDocs(q);
  
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        return data;
      } catch (error) {
        console.error('Error fetching data: ', error);
        return [];
      }
    };
  
    useEffect(() => {
      fetchData(value).then((data) => {
        setShowData(data);
        setLoading(false); // Mark the loading as complete
      });
    }, [value]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <>
        <section className="team-section mb-0">
  
          <div className="auto-container">
  
  
  
            <section className="menu-section">
              <div className="auto-container">
                <div className="title-box centered">
                  <div className="subtitle"><span>A grave tale of things yet to come</span></div>
  
                  <div className="pattern-image">
                    <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                  </div>
  
                  <h2>Information</h2>
                </div>
  
                <div className="tabs-box menu-tabs">
                  <div className="buttons">
                    <ul className="tab-buttons clearfix">
                      <li className={`tab-btn ${tabMenu.a && "active-btn"}`} onClick={() => tabActive({ a: true })} data-tab="#tab-1"><span>THE EVENT</span></li>
                      <li className={`tab-btn ${tabMenu.b && "active-btn"}`} onClick={() => tabActive({ b: true })} data-tab="#tab-2"><span>DRESS CODE</span></li>
                      <li className={`tab-btn ${tabMenu.c && "active-btn"}`} onClick={() => tabActive({ c: true })} data-tab="#tab-3"><span>WARNINGS</span></li>
                      <li className={`tab-btn ${tabMenu.d && "active-btn"}`} onClick={() => tabActive({ d: true })} data-tab="#tab-4"><span>MENU</span></li>
                    </ul>
                  </div>
                  <div className="tabs-content">
  
                    <div className={`tab ${tabMenu.a && "active-tab"}`} id="tab-1">
                      <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                          <div className="inner">
  
                            <div>
                            <p>Jacob Marley is the evening's host and with the spirits of Past Present and Future guides your guests into becoming major parts of Charles Dickens's famous Christmas story.</p>
                            <p>The grave tale begins at Jacob Marley's funeral, each table will receive an 'order of service' and inside are carols that will be sung during the evening by everyone. 
                                There will be opportunities for your guests to become well-known characters like Bob Cratchit, and dance like they are at Fezziwig's famous Christmas Eve Party!</p>
                            </div>
  
  
  
  
                          </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                          <div className="inner mr-5">
  
                            <img src={manSmoking} className='rounded mx-5' style={{ height: "300px" }} />
  
  
  
  
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div className={`tab ${tabMenu.b && "active-tab"}`} id="tab-2">
                      <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                          <div className="inner">
  
                            <h4>Victorian costumes welcome</h4>
  
                            <p className='my-3'>Christmas glitz and glam! Victorian costumes and Christmas jumpers are also welcome.</p>
                            <p> *Please note that the dress code is a recommendation, not a requirement.*</p>

                            
  
  
  
                          </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                          <div className="inner">
  
                            <img src={menFashion} style={{ height: "300px" }} className='rounded mr-3' />
                            <img src={womenFashion} style={{ height: "300px", width: "250px" }} className='rounded' />
  
  
  
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div className={`tab ${tabMenu.c && "active-tab"}`} id="tab-3">
                      <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                          <div className="inner">
                            <div className='mt-4'>
                              
                            </div>
                            <div className='my-3'>
                              
                            </div>
                            <div className='mb-5'>
                              
                                ** Be aware of the Christmas spirit, set to decide your fate,
                                As the echoes of joy will overflow your plate. **
                            </div>
                            **There is no age restriction to this event, however if younger audience would like to attend, please make sure they will be accompanied by an adult**
                          </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                          <div className="inner">
                            <img src={warning} style={{ height: "250px", width: "250px" }} className='ml-5' />
  
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div
                    className={`tab ${tabMenu.d && "active-tab"}`}
                    id="tab-4"
                  >
                    <div className="row clearfix">
                      <div className="container text-center mb-5">
                        <h4>
                          This is a sample menu and can vary depending on the
                          venue, please contact them directly.
                        </h4>
                      </div>
                      <div className="container text-center">
                        <h2>Starters</h2>
                      </div>
                      <div className="menu-col cols-lg-6 col-md-12 col-sm-12">
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6" id="boxHoverMenu">
                              <h4 class="card-title my-2  d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="inner-box" style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">
                                         Vegetable soup{" "}
                                        <span className="s-info">VEGAN</span>
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                    {" "}
                                    Vegetable soup served with a crusty bread roll (v/vg)
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 " id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-4">
                                        Paupers Platter
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                  A platter of cheese, pears, gammon ham, and fig chutney served with a crusty bread roll.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Mains
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6" id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegetarian
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix">
                                    <h5 className="ml-3">
                                        A traditional Nut Roast{" "}
                                      <span className="s-info">VEGAN</span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                A traditional Nut Roast served with seasonal vegetables (v/vg)
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">
                                      A Victorian Christmas Dinner{" "}
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                  The rich gentlemen’s’ Roast Turkey, with chestnut stuffing.
                                  Roasted root vegetables and goose fat roasted potatoes
                                  {" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h2 className="d-flex justify-content-center mt-4">
                          Dessert
                        </h2>
                        <div class="container">
                          <div class="row d-flex justify-content-start">
                            <div class="col-md-6" id="boxHoverMenu">
                              <h4 class="card-title my-2 d-flex justify-content-center">
                                Vegan
                              </h4>
                              <div className="dish-block">
                                <div className="title clearfix d-flex justify-content-center">
                                  <div className="ttl clearfix text-center">
                                    <h5 className="ml-3">
                                    A figgy pudding, served with sugared plums and orange.{" "}
                                      <span className="s-info text-center">
                                        VEGAN
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="price"></div>
                                </div>
                                <div className="text desc text-center">
                                  A figgy pudding, served with sugared plums and orange.
                                  A choice of cream or brandy cream is unavailable for the vegan option.

                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" id="boxHoverMenu">
                              <h5 class="card-title my-2 d-flex justify-content-center">
                                Standard
                              </h5>
                              <div className="dish-block ">
                                <div className="inner-box " style={boxStyles}>
                                  <div className="title clearfix d-flex justify-content-center">
                                    <div className="ttl clearfix">
                                      <h5 className="ml-3">
                                      A figgy pudding, served with sugared plums and orange.
                                      </h5>
                                    </div>
                                    <div className="price"></div>
                                  </div>
                                  <div className="text desc text-center">
                                  A figgy pudding, served with sugared plums and orange.
                                  A choice of cream or brandy cream is available.

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
  
  
              </div>
  
            </section>
  
  
  
  
            <div className="text-center mb-4"><span>Please note all our bookings are handled externally by our venue host</span></div>
  
  
            <div className="row justify-content-center clearfix">
  
              
  
            <div className="row justify-content-center clearfix">
            {showData.map((item) => (
              <div className="team-block col-lg-4 col-md-6 col-sm-12">
                <div
                  className="inner-box wow fadeInUp"
                  data-wow-duration="1500ms"
                  data-wow-delay="0ms"
                >
                  <div className="image">
                    <img src={findImgPath(item.selectWhere)} alt="" />
                    <div className="overlay-box">CLICK BELOW TO BOOK</div>
                  </div>
                  <h3>
                    <Link to={item.hotelLink}>{item.hotelName}</Link>
                  </h3>
                  <div className="designation">{item.showDate}</div>
                  <div className="text">{item.hotelLocation}</div>
                  <Link to={item.hotelLink}>
                    <button
                      type="submit"
                      className="theme-btn btn-style-one clearfix mt-2"
                    >
                      <span className="btn-wrap">
                        <span className="text-one">Book</span>
                        <span className="text-two">Book</span>
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            ))}

            <div className="team-block col-lg-4 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="image">
                  <img src={teamthree} alt="" />
                  <div className="overlay-box">
                    <div className="overlay-inner">
                      <ul className="social-box"></ul>
                    </div>
                  </div>
                </div>
                <h3>
                  <Link to="#">Coming soon...</Link>
                </h3>
                <div className="designation">2024</div>
                <div className="text">Coming soon...</div>
                <a href="https://www.quaytickets.com/bowdonrooms/en-GB/shows/the%20queen%20of%20the%20ocean%20-%20titanic%20experience/events">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix mt-3"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">Book</span>
                      <span className="text-two">Book</span>
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
  
  
  
  
              
            </div>
          </div>
        </section>
      </>
    )
  }

export default ScroogePage;