import React from 'react'
import Privateevent from '../Allmenus/Thrdmenu/Privateevent'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/resource/pattern2.jpg'

function Menuthree() {
  return (
    <>
      <Bredcrumb subtitle="THE OVERLOOK HOTEL" title="Histoire Productions" Img={Img} />
      <Privateevent />
      
      
    </>
  )
}

export default Menuthree