import React from "react";
import firebaseConfig from "../../../firebase/firebaseConfig";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { getFirestore } from "firebase/firestore";

function Deliciousmenu() {
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        dispatch({ type: "LOGIN", payload: user }); // Update the authentication state.
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      +<button className="btn btn-primary" onClick={handleGoogleSignIn}>
        Sign in with Google
      </button>
    </div>
  );
}
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const db = getFirestore(app);

export default Deliciousmenu;
