import React from "react";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/60women.jpg";
import Menusection from "../Allmenus/Fourmenu/Deliciousmenu";

function Menufour() {
  return (
    <>
      <Bredcrumb subtitle="Our Menu 4" title="DELICIOUS & AMAZING" Img={Img} />
      <Menusection />
    </>
  );
}

export default Menufour;
