import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/resource/RMS3.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller } from 'swiper';
import restro from '../../assets/images/logo.png'


SwiperCore.use([Controller]);
function Contact() {
    




    const swiper1 = useRef(null);
    const swiper2 = useRef(null);

    const [flag, setflag] = useState(false)

    const slider2 = useRef()
    const duration = 500;
    const syncPosition = (e) => {
        if (!flag) {
            setflag(false)
            if (slider2.current) {
                slider2.current.to(e.item.index, duration)
            }
            setflag(false)
        }
    }

    return (
        <>
            <div className="testimonials-section" style={{height: "800px"}} >
                <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }}></div>
                <div className="auto-container">
                    <div className="carousel-box owl-theme">
                        <Swiper className="testi-top"
                            ref={swiper1}
                            controller={{ control: swiper2.current }}
                            items={1} loop margin={10} autoplay onChange={(e) => syncPosition(e)}>
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">We went with jolly hearts to experience the evening and we certainly weren’t disappointed. 
Friendly people, gorgeous meal, amazing artefacts. But what made it, was the close contact with the crew/actors who made the whole experience ‘come alive’ and we felt their loss. Well done all of you.</div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">The atmosphere was projected throughout the evening, it was so hard to realise that us sitting, eating and talking whilst the 'crew' raced around was just as it would have been on the night. 
                                    My friends and I found it quite emotional. Well done to Bobby and all involved in the production. </div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">My mum and I went last night. We had no idea what the evening had in store for us but it exceeded all expectations. The attention to detail was fantastic from the menus, the props and even the boarding passes that had the names of real passengers. 
                                    The food was delicious and authentic. Thanks for a wonderful evening!</div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">What a first-class experience! From the minute you entered the venue, you felt like you’d stepped back in time - 
                                    the actors were fantastic and the food wonderful! Highly recommend!</div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">Definitely see this if you can. We went a few weeks ago and it’s so well done. Very emotional and respectful of those who died. The food was excellent and it was fun dressing up for it.</div>
                                </div>
                            </SwiperSlide >
                            <SwiperSlide className="slide-item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">Just back from our evening at Bristol Harbour Hotel on the 111th anniversary ... I chose this date specifically as it seemed so fitting, and I can honestly say I was not disappointed! Fantastic, moving, poignant, thank you all.</div>
                                </div>
                            </SwiperSlide >
                        </Swiper>
                        
                        <div className="thumbs-carousel-box">
                            
                        </div>
                    </div>
                </div>
            </div>

            <section className="contact-page">
                

                <div className="location-center">
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">

                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Weekdays</h4>
                                        <div className="text"> Monday to Friday <br /> <div className="more-link"><Link to="#">8am to 4pm</Link></div></div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Contact Info</h4>
                                        <div className="text">Histoire Productions, Swansea UK<br />Email : info@histoireproductions.com</div>
                                        <div className="more-link"><Link to="#">Number: 01792 203135</Link></div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <h4>Weekends</h4>
                                        <div className="text">Saturday and Sunday<br /><div className="more-link"><Link to="#">Closed</Link></div></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="c-page-form-box">
                        <div className="row clearfix">

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <div className="title-box centered">
                                    <h2>Message us</h2>
                                    <div className="text desc">Have a question about the our service? We're here to help, contact us today</div>
                                </div>
                                <div className="default-form reservation-form">
                                <form
        method="post"
        action="https://formspree.io/f/mrgwlyjb" // Specify your FormKeep endpoint here
        target="_self"
        encType="multipart/form-data"
        acceptCharset="UTF-8"
      >
        <div className="clearfix">
          <div className="form-group">
            <div className="field-inner">
              <input type="text" name="name" placeholder="Your Name" required />
            </div>
          </div>
          <div className="form-group">
            <div className="field-inner">
              <input type="text" name="email" placeholder="Your Email" required />
            </div>
          </div>
          <div className="form-group">
            <div className="field-inner">
              <input type="text" name="phone" placeholder="Phone Number" required />
            </div>
          </div>
          <div className="form-group">
            <div className="field-inner">
              <textarea name="message" placeholder="Special Request" required></textarea>
            </div>
          </div>
          <div className="form-group">
          <input type="hidden" name="g-recaptcha-response" id="g-recaptcha-response" value="" autocomplete="off" />
            <button type="submit" className="theme-btn btn-style-one clearfix">
              <span className="btn-wrap">
                <span className="text-one">send your message</span>
                <span className="text-two">send your message</span>
              </span>
            </button>
          </div>
        </div>
      </form>
                                </div>
                            </div>


                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <img className = "" src={restro} alt="" style={{backgroundSize: "cover"}}/>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            

        </>
    )
}

export default Contact