import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "../components/Header/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import About from "../components/About/Main";
import Contact from "../components/Contact/Main";
import Menuone from "../components/Menu/Menuone";
import Menutwo from "../components/Menu/Menutwo";
import Menuthree from "../components/Menu/Menuthree";
import AuthProvider from "../components/Allmenus/Fourmenu/AuthProvider";
import PrivateRoute from "./PrivateRoute";
import EventContact from "../components/eventHolder/EventContact";
import Scrooge from "../components/Menu/Scrooge";
import Menufour from "../components/Menu/Menufour";
import Chefsection from "../components/Menchefs/Chefsection";
import Main from "../components/Chef/Main";
function Index() {
  const [homepage, sethomepage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/hometwo") {
      sethomepage(false);
    } else {
      sethomepage(true);
    }
  }, [location]);

  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      {homepage && <Header />}
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/titanic" element={<Menuone />} />
          <Route path="/jfk" element={<Menutwo />} />
          <Route path="/overlook" element={<Menuthree />} />
          <Route path="/Scrooge" element={<Scrooge />} />
          <Route path="/dashboard" element={<PrivateRoute />} />
          <Route path="/ourevents" element={<About />} />
          <Route path="/team" element={<Main />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<Menufour />} />
          <Route path="/eventContact" element={<EventContact />} />
        </Routes>
      </AuthProvider>
      <Footer />
    </>
  );
}

export default Index;
