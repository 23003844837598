import React from 'react'
import Contactbanner from '../Mencontactus/Contactbanner'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/resource/hands.jpg'

function Main() {
  return (
    <>
      <Bredcrumb title="ANY QUERIES OR QUESTIONS?" subtitle="Contact Us" Img={Img} />
      <Contactbanner />
    </>
  )
}

export default Main
