import React from 'react'
import { Link } from 'react-router-dom'
import bg1 from '../../assets/images/floatLogo.png'
import bg2 from '../../assets/images/floatLogo.png'
import offerimg1 from '../../assets/images/resource/jfk_poster2.jpg'
import offerimg2 from '../../assets/images/resource/TitanicPoster.jpg'
import offerimg3 from '../../assets/images/resource/OverLookPoster.jpg'
import offerimg4 from "../../assets/images/resource/Scrooge_Main_Poster.jpg"
import menuone from "../Menu/Menuone"
import menutwo from "../Menu/Menutwo"
import menuthree from "../Menu/Menuthree"
function Offer() {

  const offers = [
    {
      image: offerimg1,
      title: 'JFK',
      link: "/jfk"
    },
    {
      image: offerimg2,
      title: 'THE QUEEN OF THE OCEAN',
      link: '/titanic'
    },
    {
      image: offerimg3,
      title: 'THE OVERLOOK HOTEL',
      link: '/overlook'
    },
    {
      image: offerimg4,
      title: "SCROOGE",
      link: "/Scrooge"
    }
    
  ];

  return (
    <>
      <section className="we-offer-section">
        <div className="left-bot-bg"><img src={bg1} alt="" title="" /></div>
        <div className="right-top-bg"><img src={bg2} alt="" title="" /></div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle"><span>Our Experiences</span></div>
            <div className="pattern-image">
              <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
            </div>
            <h2>A unique interactive experience</h2>
            <div className="text"><p>We specialise in creating immersive theatre and dining experiences – breathing life into history.</p>
                <p>From first class dining on board the RMS Titanic, in a thought-provoking tribute to one of British 
                histories most tragic nautical disasters to becoming an American agent in 1963 and trying to change 
                history by preventing the President, JFK, from being assassinated in our breakout room style event.</p>
                If scare is your thing why not enter the eerie ambience of The Overlook Hotel and become part of 
                the ghostly 4
                th of July ball in 1921. Watch how isolation drives an already unstable writer to the 
                brink, in our reimagined version of a well-known 1980’s thriller.</div>
          </div>
          <div className="row justify-content-center clearfix">
            {offers.map((offer, index) => (
              <div key={index} className="offer-block col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay={`${index * 300}ms`}>
                  <div className="image"><Link to={offer.link}><img src={offer.image} alt="" /></Link></div>
                  <h3><Link to={offer.link}>{offer.title}</Link></h3>
                  <div className="more-link"><Link to={offer.link}>Book tickets</Link></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Offer
