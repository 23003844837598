import React from 'react'
import Specialmenu from '../Allmenus/Scrooge/ScroogePage';
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/resource/scroogeMeal2.jpg'

function Scrooge() {
  return (
    <>
      <Bredcrumb subtitle="SCROOGE" title="Histoire Productions" Img={Img} />
      <Specialmenu />
      
    </>
  )
}

export default Scrooge