import React from 'react'
import Testimonialsabout from '../Menabout/Testimonialsabout'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/resource/backImage4.jpg'

function Main() {
  return (
    <>
      <Bredcrumb subtitle="OUR EVENTS" title="HISTOIRE PRODUCTIONS" Img={Img} />
      
      
      
      <Testimonialsabout />
      
      
    </>
  )
}
export default Main