import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import menuimgone from '../../assets/images/resource/Website/UK maps/Titanic/Bristol.jpg';
import menuimgWSM from '../../assets/images/resource/Website/UK maps/Overlook/Weston-Super-Mare Overlook.jpg';
import menuimgBJFK from '../../assets/images/resource/Website/UK maps/jfk/Bristol jfk.jpg';
import menuimgfour from '../../assets/images/resource/Website/UK maps/Titanic/Stoke-on-Trent.jpg';
import scroogeimg from "../../assets/images/resource/Website/UK maps/Scrooge/Bournemouth Scrooge.jpg"
import scroogeimg2 from "../../assets/images/resource/Website/UK maps/Scrooge/Swansea.jpg"

SwiperCore.use([Navigation]);


function Special() {

  

  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };


  return (
    <>
      <section className="special-offer">
        <div className="outer-container">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Histoire Productions</span>
              </div>
              <div className="pattern-image">
                <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
              </div>
              <h2>Our shows</h2>

            </div>
            <Swiper className="dish-gallery-slider" {...options}
              loop={true}
              spaceBetween={45}
              slidesPerView={4}
              navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
            >

              <SwiperSlide className="offer-block-two">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={menuimgone} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="/Menutwo">The Queen of The Ocean</Link>
                  </h4>
                  <div className="text desc">Bournemouth Pier</div>
                  
                  <div className="price">2025-04-12</div>
                  <Link to="/Menuone">
                  <button type="submit" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
                </div>
              </SwiperSlide>

              

              

              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/Menuthree">
                      <img src={menuimgWSM} alt="" />
                    </Link>
                  </div>
                  <h4>
                    <Link to="#">The Overlook Hotel</Link>
                  </h4>
                  <div className="text desc">The Diplomat Hotel And Spa</div>
                  <div className="price mb-1">2025-02-01</div>
                  <Link to="/Menuthree">
                  <button type="submit" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
                </div>
              </SwiperSlide>


              <SwiperSlide className="offer-block-two">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/Menuone">
                      <img src={menuimgone} alt="" />
                    </Link>
                  </div>
                  <h4>
                    The Queen of The Ocean
                  </h4>
                  <div className="text desc">The Diplomat Hotel And Spa</div>
                  
                  <div className="price">2025-02-22</div>
                  <Link to="/Menuone">
                  <button type="submit" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/Menutwo">
                      <img src={scroogeimg} alt="" />
                    </Link>
                  </div>
                  <h4>
                    Scrooge 
                  </h4>
                  <div className="text desc">Bournemouth Pier</div>
                  <div className="price mb-1">2024-12-21</div>
                  <Link to="/Menutwo">
                  <button type="submit" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
                </div>
              </SwiperSlide>

              <SwiperSlide className="offer-block-two">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={scroogeimg2} alt="" />
                    </Link>
                  </div>
                  <h4>
                    Scrooge
                  </h4>
                  <div className="text desc">The Brangwyn Hall</div>
                  <div className="price mb-1">2024-12-20</div>
                  <Link to="/Menuone">
                  <button type="submit" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide className="offer-block-two margin-top">
                <div className="inner-box">
                  <div className="image">
                    <Link to="/">
                      <img src={menuimgfour} alt="" />
                    </Link>
                  </div>
                  <h4>
                    The Queen Of the Ocean
                  </h4>
                  <div className="text desc">Brangwyn Hall</div>
                  <div className="price mb-1">2024-09-27</div>
                  <Link to="/Menuone">
                  <button type="submit" className="theme-btn btn-style-one clearfix">
                          <span className="btn-wrap">
                        <span className="text-one">Book</span>
                         <span className="text-two">Book</span>
                        </span>
                       </button>
                       </Link>
                </div>
              </SwiperSlide>


            </Swiper>
            <div className="swiper-button-prev">
              <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
              <span className="fal fa-angle-right"></span>
            </div>
            <div className="lower-link-box text-center">
              <Link to="/About" className="theme-btn btn-style-two clearfix">
                <span className="btn-wrap">
                  <span className="text-one">view all shows</span>
                  <span className="text-two">view all shows</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}

export default Special;
