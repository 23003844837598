import React from 'react'
import Specialmenu from '../Allmenus/Menmenu/Specialmenu';
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/resource/kennedySitting.jpg'

function Menutwo() {
  return (
    <>
      <Bredcrumb subtitle="J F K" title="Histoire Productions" Img={Img} />
      <Specialmenu />
      
    </>
  )
}

export default Menutwo