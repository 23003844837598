import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slideone from '../../assets/images/resource/darkBoat.jpg'
import Slidetwo from '../../assets/images/resource/redCoat.jpg';
import Slidetwohalf from "../../assets/images/resource/redCoat.jpg"
import Slidethree from '../../assets/images/resource/president3.jpg';
import SlideFour from "../../assets/images/resource/background_cropped.jpg"
import 'swiper/swiper.min.css';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import { useMediaQuery } from 'react-responsive';
SwiperCore.use([Navigation]);

function Banner() {
    const isSmallScreen = useMediaQuery({ maxWidth: 767 }); // Define your breakpoint here
    return (
        <Swiper
            className="banner-section banner-section-coustm"
            loop={true}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }} // Use "navigation" with lowercase, and place it outside the SwiperSlide elements
        >
            <div className="banner-container">
                <div className="banner-slider">
                    <div className="swiper-wrapper">
                        {[Slideone, Slidetwo, Slidethree, SlideFour].map((image, index) => (
                            <SwiperSlide key={index} className="swiper-slide slide-item image-layer img-fluid" style={{ backgroundImage: `url(${index === 1 && !isSmallScreen ? Slidetwohalf : index === 1 && isSmallScreen ? Slidetwo : image})`, backgroundSize: 'cover', opacity: "0.8"}}>
                                <div className="auto-container">
                                    <div className="content-box">
                                        <div className="content">

                                            <div className="clearfix">
                                                <div className="inner">
                                                    <div className="subtitle">
                                                        <span>Histoire Productions presents</span>
                                                    </div>
                                                    
                                                    <h1 className='links'><span>{index === 0 ? 'THE QUEEN OF ' : index === 1 ? 'THE OVERLOOK HOTEL' : index === 2 ? 'JFK' : index === 3 ? "SCROOGE": ""}<br />{index === 0 ? 'THE OCEAN' : ''}</span></h1>
                                                    <div className="text">{index === 0 ? "Immsersive theatre and dining experience." : index === 1 ? "Join us at the party to end all parties..." : index === 2 ? "Murder mystery meets escape room" : index === 3 ? "A grave tale of things yet to come" : ""}</div>
                                                    <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                        <div className="link">
                                                            <Link to="/about" className="theme-btn btn-style-two clearfix">
                                                                <span className="btn-wrap">
                                                                    <span className="text-one">Book a Show</span>
                                                                    <span className="text-two">Book a Show</span>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                </div>
            </div>
            <div className="swiper-button-prev">
                <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
                <span className="fal fa-angle-right"></span>
            </div>
            
        </Swiper>
    );
}

export default Banner;
