import React from 'react';
import Backgroundimage from '../../assets/images/resource/superDark.jpg';
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/logo.png'

function Main() {
    return (
        <>
            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Histoire Productions"><img src={logonew} alt="" title="Histoire Productions" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>Histoire Productions, Swansea UK</li>
                                                <li><Link to="mailto:info@histoireproductions.com">info@histoireproductions.com</Link></li>
                                                <li><Link to="tel:+88-123-123456">Booking Request : 01792 203 135</Link></li>
                                                <li>Enuqiry opening times: 8am-4pm Monday to Friday</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="newsletter">
                                            
                                            <div className="newsletter-form">
                                                <form method="post" action="/">
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">Home</Link></li>
                                        <li> <Link to="/ourevents">Shows</Link></li>
                                        <li> <Link to="/team">About us</Link></li>
                                        
                                        <li> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="https://www.facebook.com/Histoireproductions/">facebook</Link></li>
                                        <li> <Link to="https://www.instagram.com/histoireproductions/">instagram</Link></li>
                                        <li> <Link to="https://twitter.com/histoireprod?lang=en-GB">Twitter</Link></li>
                                        <li> <Link to="https://www.youtube.com/@histoireproductions4692">Youtube</Link></li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2024 Histoire Productions All Rights Reserved   |    Crafted by <Link to="https://gawaincoughlan.co.uk" target="blank">Gawain Coughlan</Link>   |    Design by <Link to="https://dribbble.com/wiktoriazadrozna?fbclid=IwAR37uKwFmeAXqSf6oMHvol6v7uqQXVNelkAkhe8IZRZaK0Civ_FHevs2pOY" target="blank">Wiktoria Zadrozna</Link></div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>
    )
}

export default Main